module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"HelloBlueOwl","short_name":"HelloBlueOwl","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5P5M5VH","includeInDevelopment":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
